<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    编辑店铺
                </div>
            </template>
            <div>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">分销店铺</el-col>
                    <el-col :span="21"> <el-input v-model="fenxiao.storename" placeholder="请输入分销店铺" /></el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">区域城市</el-col>
                    <el-col :span="21">
                        <el-select v-model="fenxiao.cityId" placeholder="请选择区域城市" size="large" style="width: 240px">
                            <el-option v-for="item in cityData" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">身份证正面</el-col>
                    <el-col :span="21">
                        <el-upload v-model:file-list="srcList" :action="upload" list-type="picture-card"
                            :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-success="handlesuccess"
                            limit="1">
                            <el-icon style="font-size:34px;">+</el-icon>
                        </el-upload>

                        <el-dialog v-model="dialogVisible">
                            <img w-full :src="dialogImageUrl" style="width:100%;height: 100%;" />
                        </el-dialog>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">身份证背面</el-col>
                    <el-col :span="21">
                        <el-upload v-model:file-list="srcList2" :action="upload" list-type="picture-card"
                            :on-preview="handlePictureCardPreview2" :on-remove="handleRemove2"
                            :on-success="handlesuccess2" limit="1">
                            <el-icon style="font-size:34px;">+</el-icon>
                        </el-upload>
                        <el-dialog v-model="dialogVisible2">
                            <img w-full :src="dialogImageUrl2" style="width:100%;height: 100%;" />
                        </el-dialog>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">营业执照</el-col>
                    <el-col :span="21">
                        <el-upload v-model:file-list="srcList3" :action="upload" list-type="picture-card"
                            :on-preview="handlePictureCardPreview3" :on-remove="handleRemove3"
                            :on-success="handlesuccess3" limit="1">
                            <el-icon style="font-size:34px;">+</el-icon>
                        </el-upload>
                        <el-dialog v-model="dialogVisible3">
                            <img w-full :src="dialogImageUrl3" style="width:100%;height: 100%;" />
                        </el-dialog>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">签约照片</el-col>
                    <el-col :span="21">
                        <el-upload v-model:file-list="srcList4" :action="upload" list-type="picture-card"
                            :on-preview="handlePictureCardPreview4" :on-remove="handleRemove4"
                            :on-success="handlesuccess4" limit="1">
                            <el-icon style="font-size:34px;">+</el-icon>
                        </el-upload>
                        <el-dialog v-model="dialogVisible4">
                            <img w-full :src="dialogImageUrl4" style="width:100%;height: 100%;" />
                        </el-dialog>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">店铺类型</el-col>
                    <el-col :span="21">
                        <el-radio-group v-model="fenxiao.storetype" class="ml-4">
                            <el-radio label="2" size="large">店面</el-radio>
                            <el-radio label="3" size="large">柜台</el-radio>
                            <el-radio label="1" size="large">连锁</el-radio>
                        </el-radio-group>
                        <el-input v-if="fenxiao.storetype == 1" size="small" style="width:100px;margin-left:10px;"
                            v-model="fenxiao.num" placeholder="请输入数量" />
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">区域团队</el-col>
                    <el-col :span="21">
                        <el-select v-model="fenxiao.agentname" placeholder="请选择区域团队" style="width: 240px">
                            <el-option v-for="item in options" :key="item.label" :label="item.label"
                                :value="item.label" />
                        </el-select>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">商铺电话</el-col>
                    <el-col :span="21"><el-input v-model="fenxiao.storephone" placeholder="请输入商铺电话" />
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">商家收款账户</el-col>
                    <el-col :span="21"> <el-input v-model="fenxiao.moneyname" placeholder="请输入商家收款账户"></el-input>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">备注</el-col>
                    <el-col :span="21">
                        <el-input v-model="fenxiao.remark" type="textarea" placeholder="请输入备注"></el-input>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #aaa5a5;border-radius: 5px;margin-top:5px;">
                    <el-col :span="3">商家收款码</el-col>
                    <el-col :span="21">
                        <el-upload v-model:file-list="srcList5" :action="upload" list-type="picture-card"
                            :on-preview="handlePictureCardPreview5" :on-remove="handleRemove5"
                            :on-success="handlesuccess5" limit="1">
                            <el-icon style="font-size:34px;">+</el-icon>
                        </el-upload>
                        <el-dialog v-model="dialogVisible5">
                            <img w-full :src="dialogImageUrl5" style="width:100%;height: 100%;" />
                        </el-dialog>
                    </el-col>
                </el-row>
                <el-row style="margin-top:5px;display: flex;justify-content: center;">
                    <el-tag style="margin-right: 10px;cursor: pointer;" @click="save">保存</el-tag> <el-tag @click="back"
                        style="cursor: pointer;">返回</el-tag>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>
import qs from 'qs';
import constant from "@/constant"
import { ref } from "vue";
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            cityData: [],
            text: ref(""),
            radio: ref('2'),
            textarea: ref(false),
            shenhe: ref(false),
            id: "",
            mid: ref(""),
            row: ref(""),
            huankuandetail: ref(""),
            huankuan: ref(false),
            activeName: ref('first'),
            tabledata: [],
            keyword: '',
            curpage: '1',
            pagesize: '10',
            totalcount: "",
            fenxiao: [{

            }],
            srcList: [],
            srcList2: [],
            srcList3: [],
            srcList4: [],
            srcList5: [],
            src: [],
            src2: [],
            src3: [],
            src4: [],
            src5: [],
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisible4: false,
            dialogVisible5: false,
            upload: constant.uploadfile,
            options: []
        }
    },
    methods: {
        handlesuccess5(file) {
            console.log(file)
            this.src5.push(file.url)
            console.log(this.srcList)
        },
        handleRemove5(file) {
            console.log(file)
            this.src5 = []
            this.srcList5 = []
            console.log(this.srcList)
        },
        handlePictureCardPreview5(file) {
            console.log(file)
            this.dialogImageUrl5 = file.url
            this.dialogVisible5 = true
        },
        handlesuccess4(file) {
            console.log(file)
            this.src4.push(file.url)
            console.log(this.srcList)
        },
        handleRemove4(file) {
            console.log(file)
            this.src4 = []
            this.srcList4 = []
            console.log(this.srcList)
        },
        handlePictureCardPreview4(file) {
            console.log(file)
            this.dialogImageUrl4 = file.url
            this.dialogVisible4 = true
        },
        handlesuccess3(file) {
            console.log(file)
            this.src3.push(file.url)
            console.log(this.srcList)
        },
        handleRemove3(file) {
            console.log(file)
            this.src3 = []
            this.srcList3 = []
            console.log(this.srcList)
        },
        handlePictureCardPreview3(file) {
            console.log(file)
            this.dialogImageUrl3 = file.url
            this.dialogVisible3 = true
        },
        handlesuccess2(file) {
            console.log(file)
            this.src2.push(file.url)
            console.log(this.srcList)
        },
        handleRemove2(file) {
            console.log(file)
            this.src2 = []
            this.srcList2 = []
            console.log(this.srcList)
        },
        handlePictureCardPreview2(file) {
            console.log(file)
            this.dialogImageUrl2 = file.url
            this.dialogVisible2 = true
        },
        handlesuccess(file) {
            console.log(file)
            this.src.push(file.url)
            console.log(this.srcList)
        },
        handleRemove(file) {
            console.log(file)
            this.src = []
            this.srcList = []
            console.log(this.srcList)
        },
        handlePictureCardPreview(file) {
            console.log(file)
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        back() {
            this.$router.go(-1)
        },
        save() {
            console.log(this.fenxiao, this.src, this.src2, this.src3, this.src4, this.src5)
            this.fenxiao.front = this.src[0]
            this.fenxiao.back = this.src2[0]
            this.fenxiao.zhizhao = this.src3[0]
            this.fenxiao.qianyue = this.src4[0]
            this.fenxiao.moneyimage = this.src5[0]
            this.axios.post(constant.savepartner2, qs.stringify(this.fenxiao), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.code == 200) {
                    this.$message.success("修改成功");
                    this.$router.go(-1)
                } else {
                    this.$message.error("修改失败");
                }
            })
        },
        edit(id) {
            console.log(id)
            this.$route.push({ path: '/editpartner', id: id })

        },
        detele(id) {
            console.log(id)
            this.axios.get(constant.detelepartner, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response)
                if (response.data.code == 200) {
                    this.$message.success("删除成功");
                    window.location.href.reload()
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        shenheclick() {
            console.log(this.row)
            this.axios.get(constant.shenhe, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    radio: this.radio,
                    text: this.text,
                    item: JSON.stringify(this.row)
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.data == 1) {
                    this.shenhe = false
                    ElMessage({
                        message: "审核成功",
                        type: "success"
                    })
                }
            })
        },
        tongguo() {
            console.log(this.radio)
            if (this.radio == 3) {
                this.textarea = false
            } else {
                this.textarea = true
            }
        },
        DisableMerchant(id, row) {
            this.shenhe = true
            this.row = row
            this.mid = id
            console.log(id)
        },
        handmoney(ID, money, row) {
            console.log(money)
            console.log(ID, this.id, row)
            row.Status = 2
            this.axios.get(constant.handmoney, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: this.id,
                    money: money,
                    ID: ID
                }
            }).then((response) => {
                console.log(response)
                if (response.data.code == 10000) {
                    ElMessage({
                        message: '手动扣款成功(' + response.data.msg + ')',
                        type: 'success',
                    })
                    row.Status = 1
                } else {
                    ElMessage({
                        message: "扣款失败(" + response.msg + ")",
                        type: 'waring'
                    })
                    row.Status = 3
                }
            })
        },
        indexMethod(index) {
            return "第" + (index + 1) + "期"
        },
        handleClose() {
            this.huankuan = false
        },
        detail(id) {
            console.log(id)
            this.id = id
            this.huankuan = true
            this.axios.get(constant.huankuandetail, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data)
                this.huankuandetail = response.data
            })
        },
        handleClick(tab) {
            let name = tab.props.name
            if (name == 'second') {
                this.status = 1
            } else if (name == 'third') {
                this.status = 2
            } else if (name == 'fourth') {
                this.status = 3
            } else if (name == 'first') {
                this.status = ''
            }
            this.init();
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        getCity() {
            this.cityData = []
            this.axios.get(constant.getCity, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    keyword: this.cityKeyWord,
                }
            }).then((response) => {
                console.log(response.data)
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    this.cityData.push({ label: element.city, value: element.id })
                }
            })
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val
            this.init();
        }
    },
    created: function () {
        var id = this.$route.query.id
        this.axios.get(constant.editpartner, {
            headers: {
                "Content-Type": "application/json"
            },
            params: {
                id: id
            }
        }).then((response) => {
            console.log(response.data)
            this.fenxiao = response.data.data
            if (response.data.data.front) {
                this.srcList.push({ url: response.data.data.front })
                this.src.push(response.data.data.front)
            }
            if (response.data.data.back) {
                this.srcList2.push({ url: response.data.data.back })
                this.src2.push(response.data.data.back)
            }
            if (response.data.data.zhizhao) {
                this.srcList3.push({ url: response.data.data.zhizhao })
                this.src3.push(response.data.data.zhizhao)
            }
            if (response.data.data.qianyue) {
                this.srcList4.push({ url: response.data.data.qianyue })
                this.src4.push(response.data.data.qianyue)
            }
            if (response.data.data.moneyimage) {
                this.srcList5.push({ url: response.data.data.moneyimage })
                this.src5.push(response.data.data.moneyimage)
            }
        })
        this.axios.get(constant.getagent).then((response) => {
            console.log(response.data)
            for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index];
                this.options.push({ label: element.Merchant, value: element.ID })
            }
        })
        this.getCity()
    }
}
</script>